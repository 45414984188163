<template>
  <page-container3>
    <div class="ant-pro-table">
      <div class="ant-pro-table-search">
        <search-render
          ref="config_render"
          :form="searchConfig"
          :model="searchConfig.model"
          :options="searchConfigOptions"
          :validateInfos="validateInfos"
          :resetFields="resetFields"
          @search="search"
        />
      </div>
      <a-card :body-style="{ padding: 0 }" ref="elRef">
        <div class="ant-pro-table-list-toolbar">
          <div class="ant-pro-table-list-toolbar-container">
            <div class="ant-pro-table-list-toolbar-left">
              <div class="ant-pro-table-list-toolbar-title">{{ adviser.name }} {{ t('汇款凭证项') }}</div>
            </div>
            <div class="ant-pro-table-list-toolbar-right">
              <a-space align="center">
                <a-button v-if="titleList.is_create" type="primary" size="small" @click="handleAdd">
                  <plus-outlined />
                  {{ titleList.addModalTitle }}
                </a-button>
                <a-button
                  v-for="btn in head_btn_list"
                  size="small"
                  :key="btn.name"
                  @click="btn.cb"
                  :type="btn.clazz"
                >
                  {{ (t && t(`${btn.name}`)) || btn.name }}
                </a-button>
                <a-button
                  size="small"
                  @click="create_payslip"
                  type="primary"
                >
                  {{ t('批量创建工资单') }}
                </a-button>

                <div class="ant-pro-table-list-toolbar-setting-item">
                  <a-tooltip :title="t('展示自定义列')">
                    <a-switch
                      :checked-children="t('用户')"
                      :un-checked-children="t('原始')"
                      v-model:checked="checkAll"
                      @change="column_change()"
                    />
                  </a-tooltip>
                </div>
                <div class="ant-pro-table-list-toolbar-setting-item">
                  <a-tooltip :title="t('显示/隐藏操作列')">
                    <a-switch
                      :checked-children="t('操作')"
                      :un-checked-children="t('操作')"
                      v-model:checked="checkAction"
                      @change="columnAction()"
                    />
                  </a-tooltip>
                </div>
              </a-space>
              <div class="ant-pro-table-list-toolbar-divider">
                <a-divider type="vertical" />
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('刷新')">
                  <reload-outlined @click="handleTableChange" />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('密度')">
                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <column-height-outlined />
                    <template #overlay>
                      <a-menu
                        style="width: 80px"
                        :selected-keys="[state.tableSize]"
                        @click="
                          ({ key }) => {
                            state.tableSize = key;
                          }
                        "
                      >
                        <a-menu-item key="default">
                          <a href="javascript:">{{ t('默认') }}</a>
                        </a-menu-item>
                        <a-menu-item key="middle">
                          <a href="javascript:">{{ t('中等') }}</a>
                        </a-menu-item>
                        <a-menu-item key="small">
                          <a href="javascript:">{{ t('紧凑') }}</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-popover
                  placement="bottomRight"
                  arrowPointAtCenter
                  trigger="click"
                  overlayClassName="ant-pro-table-column-setting-overlay"
                >
                  <template #title>
                    <div class="ant-pro-table-column-setting-title">
                      <a-checkbox
                        v-model:checked="columnState.checkAll"
                        :indeterminate="columnState.indeterminate"
                        @change="handleColumnAllClick"
                      >
                        {{ t('列展示') }}
                      </a-checkbox>
                      <a @click="reset">{{ t('重置') }}</a>
                    </div>
                  </template>
                  <template #content>
                    <span class="ant-pro-table-column-setting-list">
                      <drag-container
                        lockAxis="y"
                        dragClass="ant-pro-table-drag-ghost"
                        dropClass="ant-pro-table-drop-ghost"
                        @drop="({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)"
                      >
                        <draggable :key="column.key" v-for="column in dynamicColumnItems">
                          <div class="ant-pro-table-column-setting-list-item">
                            <drag-icon />
                            <a-checkbox
                              :checked="column.checked"
                              @change="handleColumnChange($event, column)"
                            >
                              {{ column.label }}
                            </a-checkbox>
                          </div>
                        </draggable>
                      </drag-container>
                    </span>
                  </template>
                  <a-tooltip :title="t('列设置')">
                    <setting-outlined />
                  </a-tooltip>
                </a-popover>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="screenState ? t('退出全屏') : t('全屏')">
                  <fullscreen-outlined v-if="!screenState" @click="setFull" />
                  <fullscreen-exit-outlined v-else @click="exitFull" />
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
        <!--        <a-affix :offset-top="top">-->
        <a-table
          bordered="true"
          :scroll="{ y: tableHeight.y, x: columnState.tableWidth }"
          :row-class-name="stripe"
          :row-selection="rowSelection"
          :size="state.tableSize"
          :loading="state.loading"
          :columns="dynamicColumns"
          :data-source="state.dataSource"
          :indentSize="0"
          :rowKey="'id'"
          :pagination="{
            current: state.current,
            pageSize: state.pageSize,
            total: state.total,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['50', '100', '200', '500', '1000', '2000'],
          }"
          @change="handleTableChange"
        >
          <template
            v-for="item in slotList"
            v-slot:[item.slotName]="{ index, text, record }"
            :key="item.slotName"
          >
            <span v-if="item.slotName === 'index'">
              {{ index + 1 + state.pageSize * (state.current - 1) }}
            </span>
            <span v-if="item.type === 'custom'">
              {{ item['field_' + text] }}
            </span>
            <span v-if="item.type === 'datetime'">
              {{ getDateTime(text) }}
            </span>
            <span v-if="item.type === 'date'">
              {{ getDate(text) }}
            </span>
            <span v-if="item.type === 'time'">
              {{ getTime(text) }}
            </span>
            <span v-if="item.type === 'toFixed2'">
                  {{ text.toFixed(2) }}
                </span>
            <span v-if="item.type === 'money'">
                  {{ $numeral.money(text) }}
                </span>
            <span v-if="item.type === 'color'" :style="item.cb(record) ? item.cb(record) : ''">
            <span v-if="item.content">
              {{ item.content(record) }}
            </span>
            <span v-else>
              {{ text }}
            </span>
          </span>
            <a-button
              style="padding-left: 1px"
              v-if="item.type === 'click'"
              type="link"
              @click="item.cb(record)"
            >
              {{ text }}
            </a-button>
            <!--使用槽 click 列宽设定 200-->
            <a-button
              style="padding-left: 1px"
              v-if="item.type === 'click1'"
              type="link"
              @click="item.cb(record)"
              :title="item.content(record) ? item.content(record) : text"
            >
                  <span
                    v-if="item.content"
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      display: inline-block;
                      width: 180px;
                    "
                  >
                    {{ item.content(record) }}
                  </span>
              <span
                v-else
                style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      display: inline-block;
                      width: 180px;
                    "
              >
                    {{ text }}
                  </span>
            </a-button>
            <a-tag color="green" v-if="text && item.type === 'boolean'">{{ item.name1 }}</a-tag>
            <a-tag color="red" v-if="!text && item.type === 'boolean'">{{ item.name2 }}</a-tag>
            <template v-if="item.type === 'list'">
              <a-tag color="#87d068" v-for="obj in text" :key="obj.id" style="margin-bottom: 1px">
                {{ obj.name }}
              </a-tag>
            </template>
          </template>
          <template #action="{ record }">
            <a-button
              type="primary"
              size="small"
              @click="() => handleOpenEdit(record)"
              style="margin-left: 2px; margin-bottom: 2px"
              v-if="record.is_active && titleList.is_update"
            >
              {{ t('修改') }}
            </a-button>
            <a-popconfirm
              v-if="record.is_active && titleList.is_remove"
              @confirm="() => handleDelete(record)"
            >
              <template #title>
                <p>
                  {{ t('是否确认删除') }}
                  <span style="color: dodgerblue">{{ record.realname }}</span>
                  ?
                </p>
              </template>
              <a-button size="small" type="danger" style="margin-left: 2px; margin-bottom: 2px">
                {{ titleList.del_btn ? titleList.del_btn : t('删除') }}
              </a-button>
            </a-popconfirm>
            <a-button
              size="small"
              type="dashed"
              @click="() => handleRecover(record)"
              v-if="!record.is_active && titleList.is_recover"
            >
              {{ t('恢复') }}
            </a-button>
            <a-button
              style="margin-left: 2px; margin-bottom: 2px"
              v-for="btn in btn_list"
              size="small"
              v-show="btn?.is_show ? btn.is_show(record) : true"
              :key="btn.name"
              :type="btn.clazz"
              @click="btn.cb(record)"
            >
              {{ (t && t(`${btn.name}`)) || btn.name }}
            </a-button>
          </template>
        </a-table>
        <!--        </a-affix>-->
      </a-card>
    </div>
    <form-modal
      v-bind="editModalAdd"
      v-if="editModalAdd.visible"
      :loading="confirmLoading"
      @cancel="
        () => {
          editModalAdd.visible = false;
        }
      "
      @ok="handlerOk"
    />
    <form-modal
      v-bind="editModalUpdate"
      v-if="editModalUpdate.visible"
      :loading="confirmLoading"
      @cancel="
        () => {
          editModalUpdate.visible = false;
        }
      "
      @ok="handlerOk"
    />
  </page-container3>
</template>

<script lang="ts">
import { createVNode, defineComponent, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import {
  ColumnHeightOutlined,
  ExclamationCircleOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '@/components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { message, Modal, notification } from 'ant-design-vue';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import getPageConfig from '@/components/page-model/index';
import { useRoute } from 'vue-router';
import { Record } from '@/components/page-model/typing';
import { set_custom_data_by_flag } from '@/api/sys-model';
import { GET_CUSTOME_DATA_USER } from '@/store/modules/user/actions';
import { bulk_create_payslip } from '@/api/cerp-model';
import { useI18n } from 'vue-i18n';


export default defineComponent({
  name: 'PageModel',
  props: {},
  setup() {
    const confirmLoading = ref<boolean>(false);
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const adviser = reactive({
      id: Number(route.params['id']),
      name: route.params['name'],
    });
    route.meta.title = route.params['name'];
    watchEffect(() => {
      if (route.path.split('/')[2] == 'basic-remittance') {
        route.meta.title = route.params['name'];
      }
    });
    const modalRef = reactive({
      selectedRows: new Array<number>(),
    });
    const file_ids: any = ref([]);
    const file_objs: any = ref([]);
    const current_org = store.getters['user/current_org'];
    const custome_data_user = store.getters['user/custome_data_user'];
    const checkAll = ref<boolean>(false);
    const checkAction = ref<boolean>(false);
    let formModalUpdate = false; // 判断显示创建、修改
    const tableHeight = reactive({ y: window.innerHeight - 130 });
    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 130;
      };
    });

    const path_arr = route.path.split('/');
    const field = {
      column_flag: 'column_' + path_arr[path_arr.length - 1],
    };
    const local_path = route.path;

    watch(
      () => route.path,
      () => {
        if (route.path == local_path && !route.query.q) {
          reload();
        }
      },
    );

    // 时间区间格式转化 数组-->字符串
    const getRangePicker = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string].join(',');
        }
      }
      return obj;
    };

    const {
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      queryData,
      addData,
      updateData,
      removeData,
      recoverData,
      baseColumns,
      slotList,
      titleList,
      api_fields,
      btn_list,
      head_btn_list,
      event_obj,
    } = getPageConfig('cerp-remittance');

    if (searchConfig.fields) {
      for (let i = 0; i < searchConfig.fields.length; i++) {
        // 预先填充 placeholder
        let filed_dict = searchConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }

        // 预先填充 校验message
        let search_rule_dict = searchConfig.rules[filed_dict.name];
        if (search_rule_dict) {
          if (!search_rule_dict.message) {
            search_rule_dict.message = filed_dict.placeholder;
          }
        }
      }
    }

    if (formModalConfig.fields) {
      for (let i = 0; i < formModalConfig.fields.length; i++) {
        let filed_dict = formModalConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }
      }
    }

    event_obj?.on('refresh_search', (query_data: any) => {
      Object.assign(searchConfig.model, query_data);
      search();
    });
    event_obj?.on('refresh', () => {
      reload();
    });
    event_obj?.on('reset_and_reload', () => {
      reload();
    });

    let tmpBaseColumns = null;
    if (custome_data_user && custome_data_user[field.column_flag]) {
      baseColumns.map((item: any, index: number) => {
        if (custome_data_user[field.column_flag][item.dataIndex]) {
          item.sort_num = index;
          item.sort_num = custome_data_user[field.column_flag][item.dataIndex]['index'];
          item.checked = custome_data_user[field.column_flag][item.dataIndex]['checked'];
        }
      });
      tmpBaseColumns = baseColumns.sort((a: any, b: any) => a.sort_num - b.sort_num);
    }
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(
      (tmpBaseColumns != null ? tmpBaseColumns : baseColumns) as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
    );

    watch(
      () => dynamicColumnItems,
      () => {
        const column_data_json: any = {};
        dynamicColumnItems.value.map((item, index) => {
          column_data_json[item.key] = { key: item.key, index: index, checked: item.checked };
        });

        set_custom_data_by_flag({
          org_id: current_org?.id,
          flag: field.column_flag,
          data: JSON.stringify(column_data_json),
        }).then(function () {
          store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, { org_id: Number(current_org?.id) });
        });
      },
      { deep: true },
    );
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);

    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryData, {
      current: 1,
      pageSize: 100,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...searchConfig.model,
        remittance_set_id: adviser.id,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      let filter_map: any = {};
      for (let key in filters) {
        filter_map[key] = filters[key].map((x: any) => x.toString()).join(',');
      }
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...searchConfig.model,
        remittance_set_id: adviser.id,
        ...filter_map,
      });
      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...searchConfig.model,
        remittance_set_id: adviser.id,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
      });
      reload();
    };
    // edit
    const editModalAdd = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: formModalConfig,
      options: formModalConfigOptions,
    });
    const editModalUpdate = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: updateFormModalConfig,
      options: updateFormModalConfigOptions,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      formModalUpdate = true;
      editModalUpdate.visible = true;
      editModalUpdate.title = titleList.updateTitle;
      editModalUpdate.form.model = {
        ...updateFormModalConfig.model,
        ...record,
        ...getObjList(api_fields.update, record),
      };

      currentRecord = record;
    };
    const handleAdd = () => {
      formModalUpdate = false;
      editModalAdd.visible = true;

      editModalAdd.form.model = { ...formModalConfig.model };
      editModalAdd.title = titleList.addModalTitle;
    };

    const handleDelete = (record: any) => {
      removeData({
        org_id: current_org.id,
        ...getObjList(api_fields.delete, record),
      }).then((res: any) => {
        message.success(t('删除成功'));
        Object.assign(record, res);
      });
    };
    const handleRecover = (record: any) => {
      recoverData({
        org_id: current_org.id,
        ...getObjList(api_fields.recover, record),
      }).then((res: any) => {
        message.success(t('恢复成功'));
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      confirmLoading.value = true;
      if (!formModalUpdate) {
        addData({ org_id: current_org.id, aim_org_id: editModalAdd.aim_org_id, ...data })
          .then(() => {
            message.success(t('创建成功'));
            reload();
            editModalAdd.visible = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      } else {
        updateData({
          ...data,
          org_id: current_org.id,
          ...getObjList(api_fields.updateData, data),
          ...getObjList(api_fields.updateData, editModalUpdate.form.model),
        })
          .then((res: any) => {
            Object.assign(currentRecord, res);
            message.success(t('修改成功'));
            editModalUpdate.visible = false;
            formModalUpdate = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      }
    };

    // 字段对应错误————>转化正确
    const getObjList = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string];
        }
      }
      return obj;
    };

    // 接口返回时间，格式优化
    const getDateTime = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 16);
      } else if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const getDate = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 10);
      } else if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };
    const getTime = (time: any) => {
      if (time) {
        time = time.substring(0, 5);
      }
      return time;
    };

    // 列’默认‘ ’全部‘ 展示的切换
    let old_list: any[] = []; // 定义old_list记录默认数据
    const column_change = () => {
      if (checkAll.value) {
        dynamicColumnItems.value.forEach(org => {
          old_list.push(org);
          let b = {
            ...org,
            checked: true,
          };
          let a: any = {};
          a.target = b;
          handleColumnChange(a, b);
        });
      } else {
        old_list.forEach(org => {
          let a: any = {};
          a.target = org;
          handleColumnChange(a, org);
        });
      }
    };
    const columnAction = () => {
      dynamicColumnItems.value.forEach(org => {
        if (org.key == 'action') {
          let b = {
            ...org,
            checked: checkAction.value,
          };
          let a: any = {};
          a.target = b;
          handleColumnChange(a, b);
        }
      });
    };
    const rowSelection = {
      type: 'checkbox',
      selectedRowKeys: modalRef.selectedRows,
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log('selectedRows', selectedRows);
        console.log('selectedRowKeys', selectedRowKeys);
        modalRef.selectedRows.length = 0;
        file_ids.value.length = 0;
        file_objs.value.length = 0;
        selectedRowKeys.map((item: any) => {
          modalRef.selectedRows.push(item);
        });
        selectedRows.map((item: any) => {
          if (item.invoice_id && !item?.payslip_id) {
            file_ids.value.push(item.invoice_id);
          }
          if (item?.payslip_id) {
            file_objs.value.push(item);
          }
        });
        console.log('file_ids', file_ids);
      },
    };
    const create_payslip = () => {
      if (file_ids.value.length > 0) {
        if (file_objs.value && file_objs.value.length > 0) {
          Modal.confirm({
            title: t('当前已生成的工资单不会重新创建'),
            icon: createVNode(ExclamationCircleOutlined),
            onOk() {
              return new Promise(resolve => {
                bulk_create_payslip({
                  org_id: current_org.id,
                  invoice_ids: file_ids.value,
                }).then((res: any) => {
                  message.success(t('工资单批量创建成功'));
                  resolve(null);
                });
              }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {
            },
          });
        } else {
          bulk_create_payslip({
            org_id: current_org.id,
            invoice_ids: file_ids.value,
          }).then((res: any) => {
            message.success(t('工资单批量创建成功'));
          });
        }
      } else {
        notification.error({
          message: t('未选择汇款凭证项'),
          description:
            t('请选择需要创建工资单的汇款凭证项'),
        });
      }

    };
    return {
      t,
      rowSelection,
      getDateTime,
      getTime,
      getDate,
      getRangePicker,
      column_change,
      columnAction,
      checkAll,
      checkAction,
      slotList,
      titleList,
      tableHeight,
      state,
      stripe,
      columnState,
      adviser,
      dynamicColumns,
      dynamicColumnItems,

      confirmLoading,
      handleAdd,
      handlerOk,
      currentRecord,
      resetFields,
      validateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      create_payslip,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      // edit
      editModalAdd,
      editModalUpdate,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      reload,
      getPageConfig,
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      getObjList,
      field,
      api_fields,
      btn_list,
      head_btn_list,
    };
  },
  components: {
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    ExclamationCircleOutlined,
    Draggable,
    DragContainer,
    FormModal,
    SearchRender,
  },
});
</script>


import { createVNode, defineComponent, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import {
  ColumnHeightOutlined,
  ExclamationCircleOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '@/components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { message, Modal, notification } from 'ant-design-vue';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import getPageConfig from '@/components/page-model/index';
import { useRoute } from 'vue-router';
import { Record } from '@/components/page-model/typing';
import { set_custom_data_by_flag } from '@/api/sys-model';
import { GET_CUSTOME_DATA_USER } from '@/store/modules/user/actions';
import { bulk_create_payslip } from '@/api/cerp-model';
import { useI18n } from 'vue-i18n';


export default defineComponent({
  name: 'PageModel',
  props: {},
  setup() {
    const confirmLoading = ref<boolean>(false);
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const adviser = reactive({
      id: Number(route.params['id']),
      name: route.params['name'],
    });
    route.meta.title = route.params['name'];
    watchEffect(() => {
      if (route.path.split('/')[2] == 'basic-remittance') {
        route.meta.title = route.params['name'];
      }
    });
    const modalRef = reactive({
      selectedRows: new Array<number>(),
    });
    const file_ids: any = ref([]);
    const file_objs: any = ref([]);
    const current_org = store.getters['user/current_org'];
    const custome_data_user = store.getters['user/custome_data_user'];
    const checkAll = ref<boolean>(false);
    const checkAction = ref<boolean>(false);
    let formModalUpdate = false; // 判断显示创建、修改
    const tableHeight = reactive({ y: window.innerHeight - 130 });
    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 130;
      };
    });

    const path_arr = route.path.split('/');
    const field = {
      column_flag: 'column_' + path_arr[path_arr.length - 1],
    };
    const local_path = route.path;

    watch(
      () => route.path,
      () => {
        if (route.path == local_path && !route.query.q) {
          reload();
        }
      },
    );

    // 时间区间格式转化 数组-->字符串
    const getRangePicker = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string].join(',');
        }
      }
      return obj;
    };

    const {
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      queryData,
      addData,
      updateData,
      removeData,
      recoverData,
      baseColumns,
      slotList,
      titleList,
      api_fields,
      btn_list,
      head_btn_list,
      event_obj,
    } = getPageConfig('cerp-remittance');

    if (searchConfig.fields) {
      for (let i = 0; i < searchConfig.fields.length; i++) {
        // 预先填充 placeholder
        let filed_dict = searchConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }

        // 预先填充 校验message
        let search_rule_dict = searchConfig.rules[filed_dict.name];
        if (search_rule_dict) {
          if (!search_rule_dict.message) {
            search_rule_dict.message = filed_dict.placeholder;
          }
        }
      }
    }

    if (formModalConfig.fields) {
      for (let i = 0; i < formModalConfig.fields.length; i++) {
        let filed_dict = formModalConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }
      }
    }

    event_obj?.on('refresh_search', (query_data: any) => {
      Object.assign(searchConfig.model, query_data);
      search();
    });
    event_obj?.on('refresh', () => {
      reload();
    });
    event_obj?.on('reset_and_reload', () => {
      reload();
    });

    let tmpBaseColumns = null;
    if (custome_data_user && custome_data_user[field.column_flag]) {
      baseColumns.map((item: any, index: number) => {
        if (custome_data_user[field.column_flag][item.dataIndex]) {
          item.sort_num = index;
          item.sort_num = custome_data_user[field.column_flag][item.dataIndex]['index'];
          item.checked = custome_data_user[field.column_flag][item.dataIndex]['checked'];
        }
      });
      tmpBaseColumns = baseColumns.sort((a: any, b: any) => a.sort_num - b.sort_num);
    }
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(
      (tmpBaseColumns != null ? tmpBaseColumns : baseColumns) as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
    );

    watch(
      () => dynamicColumnItems,
      () => {
        const column_data_json: any = {};
        dynamicColumnItems.value.map((item, index) => {
          column_data_json[item.key] = { key: item.key, index: index, checked: item.checked };
        });

        set_custom_data_by_flag({
          org_id: current_org?.id,
          flag: field.column_flag,
          data: JSON.stringify(column_data_json),
        }).then(function () {
          store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, { org_id: Number(current_org?.id) });
        });
      },
      { deep: true },
    );
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);

    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryData, {
      current: 1,
      pageSize: 100,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...searchConfig.model,
        remittance_set_id: adviser.id,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      let filter_map: any = {};
      for (let key in filters) {
        filter_map[key] = filters[key].map((x: any) => x.toString()).join(',');
      }
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...searchConfig.model,
        remittance_set_id: adviser.id,
        ...filter_map,
      });
      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...searchConfig.model,
        remittance_set_id: adviser.id,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
      });
      reload();
    };
    // edit
    const editModalAdd = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: formModalConfig,
      options: formModalConfigOptions,
    });
    const editModalUpdate = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: updateFormModalConfig,
      options: updateFormModalConfigOptions,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      formModalUpdate = true;
      editModalUpdate.visible = true;
      editModalUpdate.title = titleList.updateTitle;
      editModalUpdate.form.model = {
        ...updateFormModalConfig.model,
        ...record,
        ...getObjList(api_fields.update, record),
      };

      currentRecord = record;
    };
    const handleAdd = () => {
      formModalUpdate = false;
      editModalAdd.visible = true;

      editModalAdd.form.model = { ...formModalConfig.model };
      editModalAdd.title = titleList.addModalTitle;
    };

    const handleDelete = (record: any) => {
      removeData({
        org_id: current_org.id,
        ...getObjList(api_fields.delete, record),
      }).then((res: any) => {
        message.success(t('删除成功'));
        Object.assign(record, res);
      });
    };
    const handleRecover = (record: any) => {
      recoverData({
        org_id: current_org.id,
        ...getObjList(api_fields.recover, record),
      }).then((res: any) => {
        message.success(t('恢复成功'));
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      confirmLoading.value = true;
      if (!formModalUpdate) {
        addData({ org_id: current_org.id, aim_org_id: editModalAdd.aim_org_id, ...data })
          .then(() => {
            message.success(t('创建成功'));
            reload();
            editModalAdd.visible = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      } else {
        updateData({
          ...data,
          org_id: current_org.id,
          ...getObjList(api_fields.updateData, data),
          ...getObjList(api_fields.updateData, editModalUpdate.form.model),
        })
          .then((res: any) => {
            Object.assign(currentRecord, res);
            message.success(t('修改成功'));
            editModalUpdate.visible = false;
            formModalUpdate = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      }
    };

    // 字段对应错误————>转化正确
    const getObjList = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string];
        }
      }
      return obj;
    };

    // 接口返回时间，格式优化
    const getDateTime = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 16);
      } else if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const getDate = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 10);
      } else if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };
    const getTime = (time: any) => {
      if (time) {
        time = time.substring(0, 5);
      }
      return time;
    };

    // 列’默认‘ ’全部‘ 展示的切换
    let old_list: any[] = []; // 定义old_list记录默认数据
    const column_change = () => {
      if (checkAll.value) {
        dynamicColumnItems.value.forEach(org => {
          old_list.push(org);
          let b = {
            ...org,
            checked: true,
          };
          let a: any = {};
          a.target = b;
          handleColumnChange(a, b);
        });
      } else {
        old_list.forEach(org => {
          let a: any = {};
          a.target = org;
          handleColumnChange(a, org);
        });
      }
    };
    const columnAction = () => {
      dynamicColumnItems.value.forEach(org => {
        if (org.key == 'action') {
          let b = {
            ...org,
            checked: checkAction.value,
          };
          let a: any = {};
          a.target = b;
          handleColumnChange(a, b);
        }
      });
    };
    const rowSelection = {
      type: 'checkbox',
      selectedRowKeys: modalRef.selectedRows,
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log('selectedRows', selectedRows);
        console.log('selectedRowKeys', selectedRowKeys);
        modalRef.selectedRows.length = 0;
        file_ids.value.length = 0;
        file_objs.value.length = 0;
        selectedRowKeys.map((item: any) => {
          modalRef.selectedRows.push(item);
        });
        selectedRows.map((item: any) => {
          if (item.invoice_id && !item?.payslip_id) {
            file_ids.value.push(item.invoice_id);
          }
          if (item?.payslip_id) {
            file_objs.value.push(item);
          }
        });
        console.log('file_ids', file_ids);
      },
    };
    const create_payslip = () => {
      if (file_ids.value.length > 0) {
        if (file_objs.value && file_objs.value.length > 0) {
          Modal.confirm({
            title: t('当前已生成的工资单不会重新创建'),
            icon: createVNode(ExclamationCircleOutlined),
            onOk() {
              return new Promise(resolve => {
                bulk_create_payslip({
                  org_id: current_org.id,
                  invoice_ids: file_ids.value,
                }).then((res: any) => {
                  message.success(t('工资单批量创建成功'));
                  resolve(null);
                });
              }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {
            },
          });
        } else {
          bulk_create_payslip({
            org_id: current_org.id,
            invoice_ids: file_ids.value,
          }).then((res: any) => {
            message.success(t('工资单批量创建成功'));
          });
        }
      } else {
        notification.error({
          message: t('未选择汇款凭证项'),
          description:
            t('请选择需要创建工资单的汇款凭证项'),
        });
      }

    };
    return {
      t,
      rowSelection,
      getDateTime,
      getTime,
      getDate,
      getRangePicker,
      column_change,
      columnAction,
      checkAll,
      checkAction,
      slotList,
      titleList,
      tableHeight,
      state,
      stripe,
      columnState,
      adviser,
      dynamicColumns,
      dynamicColumnItems,

      confirmLoading,
      handleAdd,
      handlerOk,
      currentRecord,
      resetFields,
      validateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      create_payslip,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      // edit
      editModalAdd,
      editModalUpdate,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      reload,
      getPageConfig,
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      getObjList,
      field,
      api_fields,
      btn_list,
      head_btn_list,
    };
  },
  components: {
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    ExclamationCircleOutlined,
    Draggable,
    DragContainer,
    FormModal,
    SearchRender,
  },
});
